import React, { useEffect, useState } from "react";
import style from "./Diet-page-fc.module.scss";
import Container from "../container";
import DietAddForm from "../diet-add-form";
import DietTableFc from "../diet-table-fc";
import Heading from "../heading";
import { http } from "../../utilits/http";
import Pagination from "../pagination";
import Search from "../search";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from "../modal";

const DietPageFc = () => {
  const [dietAddData, setDietAddData] = useState({ results: [], count: 0 });
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const itemsPerPage = 15;
  const navigate = useNavigate();
  const location = useLocation();

  const fetchData = async () => { 
    try {
      const response = await http.get(
        `diet/diet-plan/?search=${searchTerm}&page=${currentPage + 1}`
      );
      setDietAddData(response.data);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchTerm, currentPage]);

  const pageCount = Math.ceil(dietAddData.count / itemsPerPage);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
    navigate(`${location.pathname}?page=${selectedPage.selected + 1}`);
  };

  const filteredData = dietAddData.results.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearch = (searchQuery) => {
    setSearchTerm(searchQuery);
    setCurrentPage(0);
  };

  const handleUpdate = async (updatedData) => {
    try {
      const response = await http.patch(
        `diet/diet-plan/${updatedData.id}/`,
        updatedData
      );
      const updatedDiet = response.data;
      const updatedDietData = dietAddData.map((diet) => {
        if (diet.id === updatedDiet.id) {
          return updatedDiet;
        }
        return diet;
      });
      setDietAddData(updatedDietData);
    } catch (error) {
      console.error("Error updating diet:", error);
    }
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div className={style.diet_container}>
      <div>
        <Search onSearch={handleSearch} />
      </div>
      <div className={style.diet_page_head}>
        <Heading name="diet plans" />
        <button
          className="input_add_btn"
          onClick={() => {
            toggleModal();
          }}
        >
          Create diet
        </button>
      </div>
      <div className="row">
        {/* <div className="col-5">
                    <DietAddForm onUpdate={fetchData} showImageField={true}/>
                </div> */}
        <div className="col-12">
          <DietTableFc dietAddData={filteredData} onUpdate={fetchData} />
        </div>
      </div>
      {pageCount > 1 && (
        <div>
          <Pagination pageCount={pageCount} onPageChange={handlePageChange} />
        </div>
      )}
      {showModal && (
        <Modal>
          <DietAddForm onUpdate={fetchData} showImageField={true} onClose={toggleModal}/>
        </Modal>
      )}
    </div>
  );
};

export default DietPageFc;
