import React , {useState , useEffect} from "react";
import style from './Accept-food-fc.module.scss';
import FoodAddForm from "../food-add-form";
import FoodTable from "../food-listing-table";
import Container from "../container";
import Heading from "../heading";
import ReqTableCustomer from "../customer-accept-food-table";
import Search from "../search";
import { http } from "../../utilits/http";

const AcceptFoodFc = () => {
    const [acceptFood, setacceptFood] = useState([]);
    const [initialFormValues, setInitialFormValues] = useState(
        { food_item: "" , calories:"" , carbs:"" , fat:"" , proteins:"", units:[] , unit:"" , image:"" }
    );

    useEffect(()=> {
        const fetchData = async () => {
            try{
                const response = await http.get('diet/fitness-food-request/')
                setacceptFood(response.data.results)
            }catch(error){
                console.log('Error fetching data:' , error)
            }
        }
        fetchData();
    },[]);

    const handleAdd = async (newFoodReq) => {
        try {
          const formData = new FormData();
          formData.append("name", newFoodReq.name);
          formData.append("calories", newFoodReq.calories);
          formData.append("carbs", newFoodReq.carbs);
          formData.append("fat", newFoodReq.fat);
          formData.append("protein", newFoodReq.protein);

          formData.append("units", JSON.stringify(newFoodReq.units));
      
          formData.append("image", newFoodReq.image);
      
          const response = await http.post("diet/fitness-food-request-create/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
      
          const addedFood = response.data;
          setacceptFood([...acceptFood, addedFood]);
          setInitialFormValues({
            name: "",
            image: "",
            calories: "",
            carbs: "",
            fat: "",
            protein: "",
            units: [], 
          });
        } catch (error) {
          console.error("Error adding diet food request:", error);
        }
    };
      
    const handleDelete = async (id) => {
        try {
            await http.delete(`diet/fitness-food-request-create/${id}/`);
            const updatedFoodList = acceptFood.filter(food => food.id !== id);
            setacceptFood(updatedFoodList);
        } catch (error) {
            console.error("Error deleting unit:", error);
        }
    };
    
    return(
        <Container>
            <div className={style.acceptfood_container}>
                <div>
                    <Search />
                </div>
                <div className={style.food_page}>
                    <Heading name="food" />
                </div>
                <div className="row">
                    <div className="col-6">
                        <FoodAddForm initialFormValues={initialFormValues} addFoodItem={handleAdd} />
                    </div>
                    <div className="col-6">
                        <ReqTableCustomer reqFood={acceptFood} onDelete={handleDelete}/>
                    </div>
                </div>
            </div>
        </Container>
    )
}
export default AcceptFoodFc;