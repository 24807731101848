import React from "react";
import style from "./Navbar.module.scss";
import logo from "../../assets/images/Disciple_logo.png";
import { CONFIG } from "../../config/config";
import { CONST } from "../../config/const";
import NavItem from "../nav-item";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";


const Navbar = ({data}) => {
  return (
    <nav className={style.nav}>
      <div className={style.header}>
        <a href={CONFIG.BASE_URL}>
          <img src={logo} alt="discple" className={style.logo} />
        </a>
      </div>
      <div className={style.nav_items_wrapper}>
        <ul className={style.nav_items}>
          {data?.routes?.map((nav, index) => {
            return (
              <li key={index}>
                <NavItem data={nav} info={data}/>
              </li>
            );
          })}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
