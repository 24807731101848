import React, { useState } from 'react';
import '../../styles/global.css';

function MultiSelectDropdown(props) {
  const { options, onChange, placeholder } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionChange = (option) => {
    const updatedSelections = selectedOptions.includes(option)
      ? selectedOptions.filter((selected) => selected !== option)
      : [...selectedOptions, option];
  
    console.log('Updated Selections:', updatedSelections); 
  
    setSelectedOptions(updatedSelections);
  
    if (typeof onChange === 'function') {
      onChange(updatedSelections);
    }
  };
  

  return (
    <div className="multi-select-dropdown">
      <div className={`dropdown ${isOpen ? 'open' : ''}`}>
          <div className="dropdown-toggle" onClick={toggleDropdown}>
              {selectedOptions.length === 0 ? (
                  placeholder || 'Select'
              ) : (
                  selectedOptions.join(', ')
              )}
          </div>
        <div className={`dropdown-menu ${isOpen ? 'show' : ''}`}>
          {isOpen &&
            options.map((option,index) => (
              <div key={index} className="check-box-value">
                <input
                  type="checkbox"
                  value={option.value}
                  checked={selectedOptions.includes(option)}
                  onChange={() => handleOptionChange(option)}
                />
                {option.label}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default MultiSelectDropdown;
