import React, { useState } from "react";
import style from "./Food-listing.module.scss";
import MainTable from "../table";
import { BiDotsVerticalRounded } from "react-icons/bi";
import Modal from "../modal";
import "../../styles/global.css";
import FoodAddForm from "../food-add-form";
import ClickAwayListener from "react-click-away-listener";

const FoodTable = ({ addFood, onUpdate, onDelete , showImageField}) => {
    const [showMenu, setShowMenu] = useState(-1);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [itemToUpdate, setItemToUpdate] = useState(null);

    const FoodTableFields = [
      "sl.no",
      "food item",
      "calories (per 100 gm)",
      "CARBS (GM)",
      "fat (gm)",
      "proteins (gm)",
      "actions",
    ];

    const toggleMenu = (index) => {
      setShowMenu(showMenu === index ? -1 : index);
    };

    const handleDeleteClick = (data) => {
      setItemToDelete(data);
      setDeleteModalOpen(true);
    };

    const confirmDelete = () => {
      onDelete(itemToDelete);
      setDeleteModalOpen(false);
      setItemToDelete(null);
      setShowMenu(-1);
    };
    
    const handleUpdateClick = (data) => {
      console.log(data)
      setItemToUpdate(data);
      setUpdateModalOpen(true);
      setShowMenu(-1);
    };

    // const confirmUpdate = (updatedData) => {
    //   onUpdate(updatedData);
    //   setUpdateModalOpen(false);
    //   setItemToUpdate(null);
    // };

  return (
    <div className={style.food_list_table}>
      <MainTable headers={FoodTableFields}>
        {addFood.map((data, index) => {
          return (
            <tr className="table_body" key={index}>
              <td className="table_body_data">{index+1}</td>
              <td className="table_body_data"><p>{data.name}</p></td>
              <td className="table_body_data">{data.calories}</td>
              <td className="table_body_data">{data.carbohydrates}</td>
              <td className="table_body_data">{data.fat}</td>
              <td className="table_body_data">{data.proteins}</td>
              <td className="table_body_action">
                <div className="menu_dropdown">
                  <BiDotsVerticalRounded onClick={() => toggleMenu(index)} />
                  {showMenu === index && (
                    <ClickAwayListener onClickAway={()=>setShowMenu(false)}>
                      <div className="dropdownContent">
                        <div>
                          <button
                            className="action_button"
                            onClick={() => handleUpdateClick(data)}
                          >
                            Edit
                          </button>
                        </div>
                        <div>
                          <button
                            className="action_button"
                            onClick={() => handleDeleteClick(data)}
                          >
                            Delete
                          </button>
                        </div>
                      </div> 
                    </ClickAwayListener> 
                  )}
                </div>
              </td>
            </tr>
          );
        })}
      </MainTable>
      {deleteModalOpen && (
        <Modal closeModal={() => setDeleteModalOpen(false)}>
          <div>
            <div className="modal_head">Remove diet</div>
            <p className="modal_msg">
              Are you sure do you want to remove the food?
            </p>
            <button className="modal_yes" onClick={confirmDelete}>
              Yes
            </button>
            <button
              className="modal_no"
              onClick={() => setDeleteModalOpen(false)}
            >
              Cancel
            </button>
          </div>
        </Modal>
      )}
      {updateModalOpen && (
        <Modal
          isLarge={true}
          onClose={() => {
            setUpdateModalOpen(false);
            setShowMenu(false);
          }}
          showClose
        >
        <FoodAddForm
            initialFormValues={itemToUpdate}
            onUpdate={onUpdate}
            closeModal={() => setUpdateModalOpen(false)}
            isEdit={!!itemToUpdate}
            showImageField={true}
          />
        </Modal>
      )}
    </div>
  );
};
export default FoodTable;
