import React, { useEffect, useState } from "react";
import style from './payment-module-table.module.scss';
import PaymentRequestTable from "../payment-request-table";
import { RiFileDownloadFill } from "react-icons/ri";
import { useNavigate, useSearchParams } from "react-router-dom";
import { http } from "../../utilits/http";
import PaymentModal from "../paynow-modal";
import {toast} from 'react-toastify';

const PaymentRequestTab = ({setTabOption , tabOption , data , onComplete}) => {
    const [paymentModal , setPaymentModal ] = useState(false);
    const [params, setParams] = useSearchParams();
    const [selectedRows, setSelectedRows] = useState([]);
    const navigate = useNavigate();

    const OPTIONS = [
        { label: "Customer", selected: "customer" },
        { label: "Store", selected: "store" },
    ];

    const handleTabSwitch = (item) => {
        setTabOption(item);
    };

    const handlePayment = () => {
        http.post(`user/payment-admin/` , {
            user_type:tabOption,
            payment_ids: selectedRows
        })
        .then((res) => {
            setPaymentModal(false)
            onComplete();
            setSelectedRows('');
            toast.success("Paid successfully");
        })
        .catch((err) => {
            toast.success("Payment failed");
            console.log(err);
        })
    };

    const handleDownload = () => {
        http.get(`user/download-csv-admin?user_type=${tabOption}&payment_ids=${selectedRows}`)
        .then((res) => {
            setSelectedRows("");
            window.open(`https://blacksquad.dev.fegno.com/api/v1/user/download-csv-admin/?user_type=${tabOption}&payment_ids=${selectedRows}`, '_blank' )
        })
        .catch((err) => {
            console.log(err);
        })
    };

    return (
        <div className={style.tab_wrapper}>
            <div className={style.tab_options}>
                {OPTIONS.map((option, index) => (
                    <div
                        className={`${style.options} ${tabOption === option.selected ? style.active : ''}`}
                        key={index}
                        onClick={() => handleTabSwitch(option.selected)}
                    >
                        {option.label}
                    </div>
                ))}
            </div>
            <div className={style.btn_download}>
                <button 
                    type="button" 
                    onClick={handleDownload}
                >
                    <span><RiFileDownloadFill/></span>Download
                </button>
            </div>
            {/* {tabOption === "store" && ( */}
                <div className={style.btn_store}>
                    <button 
                        type="button" 
                        className={`${style.paye_now} ${selectedRows?.length === 0 ? (style.disabled) : ''}`} 
                        disabled={selectedRows?.length === 0}
                        onClick={()=> {setPaymentModal(true)}}
                    >
                        Pay Now
                    </button>
                </div>
            {/* )} */}
            {paymentModal && (
               <PaymentModal setPaymentModal={setPaymentModal} handlePayment={handlePayment} /> 
            )}
            <div className={style.table_wrapper}>
                {tabOption === "customer" ? (
                    <PaymentRequestTable 
                        data={data} 
                        setSelectedRows={setSelectedRows}
                        selectedRows={selectedRows}
                    />
                ) : (
                    <PaymentRequestTable 
                        setSelectedRows={setSelectedRows}
                        selectedRows={selectedRows}
                        data={data} 
                    />
                )}
            </div>
        </div>
    );
};

export default PaymentRequestTab;
