import React, { useState, useEffect } from "react";
import style from "./Diet-list.module.scss";
import MainTable from "../table";
import Heading from "../heading";
import { http } from "../../utilits/http.js";
import "../../styles/global.css";
import Pagination from "../pagination";
import Search from "../search";
import { useLocation, useNavigate } from "react-router-dom";
import { BiDotsVerticalRounded } from "react-icons/bi";
import ClickAwayListener from "react-click-away-listener";
import Modal from "../modal/index.jsx";
import DietAddForm from "../diet-add-form/index.jsx";

const DietList = () => {
  const [data, setData] = useState({ results: [], count: 0 });
  const [showMenu, setShowMenu] = useState(-1);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [createOpenModal, setOpenCreateModal] = useState(false);
  const [itemToUpdate, setItemToUpdate] = useState(null);
  const itemsPerPage = 15;
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dietFields = [
    "sl.no",
    "diet name",
    "dietician name",
    "duration",
    "price",
    "status",
    "action",
  ];

  const fetchData = async () => {
    try {
      const response = await http.get(
        `diet/diet-plan/?search=${searchTerm}&page=${currentPage + 1}`
      );
      setData(response.data);
      // console.log(response.data, "res");
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, searchTerm]);

  const pageCount = Math.ceil(data.count / itemsPerPage);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
    navigate(`${location.pathname}?page=${selectedPage.selected + 1}`);
  };

  const filteredData = data.results.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearch = (searchQuery) => {
    setSearchTerm(searchQuery);
    setCurrentPage(0);
  };

  const handleEditDietClick = (id) => {
    if (id) {
      navigate(`/meals-page/${id}/?token=${queryParams.get("token")}`);
    }
  };

  const toggleMenu = (index) => {
    setShowMenu(showMenu === index ? -1 : index);
  };

  const handleUpdateClick = (data) => {
    setItemToUpdate(data);
    setUpdateModalOpen(true);
  };

  const confirmUpdate = (updatedData) => {
    fetchData();
    setUpdateModalOpen(false);
    setItemToUpdate(null);
  };

  return (
    <div className={style.diet_page}>
      <div className={style.search_bar}>
        <Search onSearch={handleSearch} />
      </div>
      <div className={style.diet_head}>
        <Heading name="Diet Plans" />
        <button
          className="input_add_btn"
          onClick={() => {
            setOpenCreateModal(true);
          }}
        >
          Create diet
        </button>
      </div>
      <div className={style.diet_list}>
        <MainTable headers={dietFields}>
          {filteredData.map((diet, index) => {
            const status = diet.is_active ? "active" : "inactive";
            return (
              <tr className="table_body" key={index}>
                <td className="table_body_data">{index + 1}</td>
                <td className="table_body_data">
                  <p>{diet.name}</p>
                </td>
                <td className="table_body_data">
                  <p>{diet.dietitian_name}</p>
                </td>
                <td className="table_body_data">{diet.duration}</td>
                <td className="table_body_data">{diet.price}</td>
                <td
                  className={`table_body_data ${
                    diet.is_active ? "active-status" : "pending-status"
                  }`}
                >
                  {status}
                </td>
                <td className="table_body_action">
                  {/* {isLoading && <Loading />} */}
                  {diet?.is_editable && (
                    <div className="menu_dropdown">
                      <BiDotsVerticalRounded
                        onClick={() => toggleMenu(index)}
                      />
                      {showMenu === index && (
                        <ClickAwayListener
                          onClickAway={() => setShowMenu(false)}
                        >
                          <div className="dropdownContent">
                            <div>
                              <button
                                className="action_button"
                                onClick={() => handleUpdateClick(diet)}
                              >
                                Edit Diet
                              </button>
                            </div>
                            <div>
                              <button
                                className="action_button"
                                onClick={() => handleEditDietClick(diet.id)}
                              >
                                Edit Meals
                              </button>
                            </div>
                          </div>
                        </ClickAwayListener>
                      )}
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </MainTable>
      </div>
      {updateModalOpen && (
        <Modal onClose={() => setUpdateModalOpen(false)} showClose>
          <DietAddForm
            initialFormValues={itemToUpdate}
            onUpdate={confirmUpdate}
            onClose={() => {
              setUpdateModalOpen(false);
              setShowMenu(false);
            }}
            isEdit
          />
        </Modal>
      )}
      {createOpenModal && (
        <Modal onClose={() => setOpenCreateModal(false)} showClose>
          <DietAddForm
            onUpdate={confirmUpdate}
            onClose={() => {
              setOpenCreateModal(false);
              setShowMenu(false);
            }}
          />
        </Modal>
      )}
      {pageCount > 1 && (
        <div>
          <Pagination pageCount={pageCount} onPageChange={handlePageChange} />
        </div>
      )}
    </div>
  );
};

export default DietList;
