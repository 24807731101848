import React, { useEffect, useState } from "react";
import style from "./Meal-page.module.scss";
import DietPlan from "../plan-detail";
// import { Container } from "react-bootstrap";
import Heading from "../heading";
import MealForm from "../meal-add-form";
import MealTable from "../meal-table";
import { http } from "../../utilits/http";
import PlanDetails from "../plan-details";
import { useLocation, useParams } from "react-router-dom";
import Modal from "../modal";
import sucsess from "../../assets/images/check (2) 1.png";

const MealPage = () => {
  const [addMeal, setAddMeal] = useState([]);

  const [data, setData] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const params = useParams();

  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const queryParams = new URLSearchParams(location.search);

  const getData = () => {
    setLoading(true);
    http
      .get(`diet/user-diet-plan/${params?.planId}/`)
      .then((res) => {
        setLoading(false);
        if (res?.data?.payment) {
          res?.data?.payment.map((item, index) =>
            setIsModalOpen(
              item?.is_shown === false && item?.status === "Success"
                ? true
                : false
            )
          );
        }
        if (res?.data) {
          setData(res?.data);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleOKButtonClick = () => {
    http
      .patch(`diet/is_shown/${params?.planId}/`)
      .then((response) => {
        setIsModalOpen(false);
      })
      .catch((error) => {
        setIsModalOpen(false);
      });
  };
  useEffect(() => {
    //getting diet paln details
    getData();
  }, []);
  console.log(params?.planId, 'idd');
  return (
    <div className={style.meal_page_container}>
      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <div className={style.succes_modal}>
            <div className={style.success_img}>
              <img src={sucsess} />
            </div>
            <div className={style.success_head}>Payment successful!</div>
            <p className={style.success_msg}>
              The meal adding option is now unlocked. Feel free to start adding
              dishes!
            </p>
            <button className={style.modal_btn} onClick={handleOKButtonClick}>
              ok
            </button>
          </div>
        </Modal>
      )}
      <div className={style.diet_plan_section}>
        <DietPlan data={data} onUpdate={getData} showedit={true} />
      </div>
      <div className={style.plan_section}>
        <PlanDetails data={data} />
      </div>
      <div className={style.meal_add_section}>
        <div className="row">
          <div className={`col-6 ${style.meal_form_col}`}>
            <Heading name="meal" />
            <MealForm
              onUpdate={getData}
              dietPlanId={params?.planId}
              userDietPlanId={params?.planId}
            />
          </div>
          <div className={`col-6 ${style.meal_table}`}>
            <MealTable
              meals={data?.meals}
              addMeal={addMeal}
              onUpdate={getData}
              userDietPlanId={params?.planId}
              dietPlanId={data?.diet_plan?.id}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default MealPage;
