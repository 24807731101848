import React, { useEffect, useState } from "react";
import style from './Store-listing-page.module.scss';
import Container from "../container";
import StoreFilter from "../store-filter";
import StoreTab from "../store-tab";
import { http } from "../../utilits/http";
import Pagination from "../pagination";
import { useLocation, useNavigate } from "react-router-dom";

const StoreListing = () => {
    const [tabOption, setTabOption] = useState("approved");
    const [ data , setData ] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 15;
    const navigate = useNavigate();
    const location = useLocation();
    
    const fetchData = () => {
        http.get(`/vendor/store-list-admin/?status=${tabOption}&page=${currentPage + 1}`)
        .then((res) => {
            setData(res?.data)
        })
        .catch((err) => {
            console.log(err)
        })
    };

    useEffect(() => {
        fetchData();
    },[tabOption,currentPage])

    const handleComplete = () => {
        fetchData();
    };

    const pageCount = Math.ceil(data.count / itemsPerPage);

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
        navigate(`${location.pathname}?page=${selectedPage.selected + 1}`);
    };

    return(
        <Container>
            <div className={style.store_listing_container}>
                <div className={style.head_wrapper}>
                    {/* <div className={style.filter}>
                        <StoreFilter 
                            filterOptions={filterOptions} 
                            setFilterOptions={setFilterOptions}
                            checked={checked}
                            setChecked={setChecked}
                            handleOptionSelect={handleOptionSelect}
                        />
                    </div> */}
                </div>
                <div className={style.content_wrapper}>
                    <div className={style.head}>Store</div>
                    <div className={style.tab_wrapper}>
                        <StoreTab 
                            tabOption={tabOption}
                            setTabOption={setTabOption}
                            data={data?.results}
                            onComplete={handleComplete}
                        />
                    </div>
                </div>
                {pageCount > 1 && (
                    <div>
                        <Pagination pageCount={pageCount} onPageChange={handlePageChange} />
                    </div>
                )}
            </div>
        </Container>
    )
}
export default StoreListing;