import React from "react";
import style from './Input-field.module.scss';

const InputField = ({ value, label, name, placeholder, type, onChange ,onBlur , width , isRequired }) => {
    const inputWidth = width === "large"
        ? style.input_large
        : width === "small"
        ? style.input_small
        : style.input_extra_small;
   
    return(
        <div className={style.input_wrapper}>
            <div className={style.input_label}>{label && <label htmlFor="input-field">{label}{isRequired && <span className={style.required_star}>*</span>}</label>}</div>
            <div className={`${style.form_input} ${inputWidth}`}>
                <input
                    type={type}
                    value={value}
                    name={name}
                    placeholder={placeholder}
                    onChange={onChange}
                    onBlur={onBlur}
                />
            </div>
        </div>
    )
}
export default InputField;