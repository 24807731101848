import React , {useState} from "react";
import style from './outstanding-tab.module.scss';
import MainTable from "../table";

const OutstandingTab = ({setTabOption , tabOption , data , onComplete}) => {

    const tableField = [ "slno" , "user name" , "total outstanding"];
    const storeTableFields = [ "slno" , "store id" , "user name" , "total outstanding" ];

    const OPTIONS = [
        { label: "Customer", selected: "customer" },
        { label: "Store", selected: "store" },
    ];

    const handleTabSwitch = (item) => {
        setTabOption(item);
    };

    return(
        <div className={style.tab_wrapper}>
            <div className={style.tab_options}>
                {OPTIONS.map((option, index) => (
                    <div
                        className={`${style.options} ${tabOption === option.selected ? style.active : ''}`}
                        key={index}
                        onClick={() => handleTabSwitch(option.selected)}
                    >
                        {option.label}
                    </div>
                ))}
            </div>
            <div className={style.table_wrapper}>
                {tabOption === "customer" ? (
                    <MainTable headers={tableField} >
                        {data?.map((item, index) => (
                            <tr className="table_body" key={index}>
                                <td className="table_body_data">{index+1}</td>
                                <td className="table_body_data">{item.user_name}</td>
                                <td className="table_body_amount">₹{item?.total_outstanding}</td>
                            </tr>
                        ))}
                    </MainTable>
                ) : (
                    <MainTable headers={storeTableFields} >
                        {data?.map((item, index) => (
                            <tr className="table_body" key={index}>
                                <td className="table_body_data">{index+1}</td>
                                <td className="table_body_data">{item?.store_id}</td>
                                <td className="table_body_data">{item.user_name}</td>
                                <td className="table_body_amount">₹{item?.total_outstanding}</td>
                            </tr>
                        ))}
                    </MainTable>
                )}
            </div>
        </div>
    )
}
export default OutstandingTab;