import React , { useState , useEffect } from "react";
import style from './outstanding.module.scss';
import OutstandingTab from "../outstanding-tab";
import Container from "../container";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { http } from "../../utilits/http";
import Pagination from "../pagination";

const Outstanding = () => {
    const [tabOption, setTabOption] = useState("customer");
    const [ data , setData ] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 15;
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        http.get(`user/outstanding-admin?user_type=${tabOption}&page=${currentPage + 1}`)
        .then((res) => {
            setData(res?.data)
        })
        .catch((err) => {
            console.log(err)
        })
    },[tabOption , currentPage])

    const pageCount = Math.ceil(data?.count / itemsPerPage);

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
        navigate(`${location.pathname}?page=${selectedPage.selected + 1}`);
    };

    return(
        <Container>
            <div className={style.outstanding_wrapper}>
                <div className={style.heading}>
                    Outstanding
                </div>
                <div className={style.outstanding_tab}>
                    <OutstandingTab 
                        tabOption={tabOption}
                        setTabOption={setTabOption}
                        data={data?.results}
                    />
                </div>
                {pageCount > 1 && (
                    <div>
                        <Pagination pageCount={pageCount} onPageChange={handlePageChange} />
                    </div>
                )}
            </div>
        </Container>
    )
}
export default Outstanding;