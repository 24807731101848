import React , {useState} from "react";
import style from './Goal-form.module.scss';
import { Formik , ErrorMessage } from "formik";
import * as Yup from "yup";
import InputField from "../input-field";
import '../../styles/global.css';
import Loading from "../loading";
import { toast } from 'react-toastify';
import { http } from "../../utilits/http";


const GoalForm = ({ initialFormValues , onUpdate , closeModal , isEdit}) => {
    const formModeClass = isEdit ? 'edit-mode' : 'add-mode';
    const formModeHeading = isEdit ? 'Edit goal' : 'Add goal';
    const [loading, setLoading] = useState(false);
    const [showError , setShowError] = useState("");

    const onSubmitHandler = async (values, { resetForm }) => {
        setShowError("");
        setLoading(true);
        values.name = values.name.toLowerCase();
        if (isEdit) {  
            http.patch(`diet/diet-goals-admin/${initialFormValues.id}/`, values)
                .then((res) => {
                    onUpdate();
                    resetForm('data:', values);
                    closeModal()
                    setLoading(false);
                    toast.success('Goal edited successfully');
                })
                .catch((err) => {
                    // console.log(err);
                    setLoading(false);
                    if (err.response) {
                        toast.error(err.response.data.message || 'Failed to edit goal');
                        setShowError(err.response.data.message);
                    } else {
                        toast.error('Failed to edit goal');
                        setShowError("An error occurred while sending the request");
                    }
                });
        } else {
            http.post('diet/diet-goals-admin/', values) 
            .then((res) => {
                onUpdate();
                resetForm('data:', values);
                console.log('data', res);
                toast.success('Goal created successfully');
                setLoading(false)
            })
            .catch((err) => {
                // console.log(err);
                setLoading(false);
                // toast('failed to create goal');
                // setShowError(err?.response?.data);
                if (err.response) {
                    toast.error(err.response.data.message || 'Failed to add goal');
                    setShowError(err.response.data.message);
                } else {
                    toast.error('Failed to add goal');
                    setShowError("An error occurred while sending the request");
                }
            });
        }
    };
    

    const handleClear = (resetForm) => {
        resetForm(initialFormValues);
    }
    const INITIALVALUES = {
        name: initialFormValues ? initialFormValues.name : "",
    };

    return(
        <div className={`form_container ${formModeClass}`}>
            {loading && <Loading />}
            <div className="form_head">
                {formModeHeading}
            </div>
            <hr className="seperator"/>
            <Formik  
                initialValues={INITIALVALUES}
                validationSchema={Yup.object().shape({
                    // name:Yup.string().required("Goal is required").matches(/^[A-Za-z\s]+$/, 'Enter valid goal').max(30, 'Goal must be at most 30 characters')
                    name: Yup.string()
                        .required("Goal name is required")
                        .test('No-empty-spaces', 'Enter a valid goal name', (value) => {
                        return value.trim() !== '';
                    })
                    .matches(/^[A-Za-z\s]+$/, 'Enter a valid goal name')
                    .max(20, 'Goal name must be at most 20 characters'),
                })}
                onSubmit={onSubmitHandler}
            >
            {({ values , handleChange , handleBlur , handleSubmit , resetForm })=>(
               <form onSubmit={handleSubmit} className="form_wrapper">
                    <div className={style.form_field}>
                        <InputField 
                            type="text"
                            name="name"
                            value={values.name}
                            label="goal name"
                            placeholder="name of the goal"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            width="small"
                            isRequired={true}
                        />
                    </div>
                    <div className="error_msg">
                        <ErrorMessage name="name" />
                    </div>
                    {/* <div className="error_msg">{showError && <div className="error-message">{showError.message}</div>}</div> */}
                    <div className="button_wrapper">
                        {!initialFormValues && ( 
                            <button className="input_clear_btn" type="button" onClick={() => handleClear(resetForm)}>
                                Clear
                            </button>
                        )}
                        <button className="input_add_btn" type="submit">
                            {initialFormValues ? "Update" : "Add"}
                        </button>
                        {initialFormValues && ( 
                            <button className="input_cancel_btn" type="button" onClick={closeModal}>
                                Cancel
                            </button>
                        )}
                    </div>
                </form>
            )}
            </Formik>
        </div>
    )
}
export default GoalForm;

