import React from "react";
import style from './Pagination.module.scss';
import ReactPaginate from 'react-paginate';
import {SlArrowRight , SlArrowLeft} from 'react-icons/sl';

const Pagination = ({ pageCount, onPageChange }) => {
   
  return (
        <div className={style.pagination_container}>
            <ReactPaginate
                pageCount={pageCount}
                onPageChange={onPageChange}
                containerClassName="pagination"
                activeClassName="active"
                previousLabel={<SlArrowLeft />}
                nextLabel={<SlArrowRight />}
                breakLabel="..."
                pageRangeDisplayed={4}
            />
        </div>
  );
};

export default Pagination;
