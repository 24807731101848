import React, { useEffect } from "react";
import style from "./UserDietPalnCreate.module.scss";
import EnquiryForm from "../enquiry-form";
import { useLocation, useParams } from "react-router-dom";
import { useState } from "react";
import { http } from "../../utilits/http";

const UserDietPlanCreate = () => {

  const [enquiry, setEnquiry] = useState("");

  const [customerId, setCustomerId] = useState("");

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const QUERYNAME = "enquiry";
  const CUSTOMER_ID_QUERY = "user";

  const getEnquiryDetails = (id) => {
    http
      .get(`diet/enquiry-detail/${id}`)
      .then((res) => {
        if (res?.data) {
          setEnquiry(res.data);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (queryParams.get(QUERYNAME)) {
      getEnquiryDetails(queryParams.get(QUERYNAME));
      console.log('Query String:', queryParams.get(QUERYNAME));
    }
    if(queryParams.get(CUSTOMER_ID_QUERY)){
      setCustomerId(queryParams.get(CUSTOMER_ID_QUERY))
    console.log('customer-id',queryParams.get(CUSTOMER_ID_QUERY))
    }
  }, []);

  return (
    <div className={style.user_diet_plan}>
      <EnquiryForm initialFormValues={enquiry} customerId={customerId} isCreate />
    </div>
  );
};

export default UserDietPlanCreate;
