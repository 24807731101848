import React, { useState } from "react";
import style from './Table.module.scss';
import { Table } from "react-bootstrap";

const MainTable = ({ headers, children }) => {

    return (
        <div className={style.table_container}>
            <Table className={style.table_wrapper}>
              <thead>
                  <tr className={style.table_head}>
                    {headers.map((header, index) => (
                        <th className={style.table_title} key={index}>{header}</th>
                    ))}
                  </tr>
              </thead>
              <tbody>
                  {children}
              </tbody>
            </Table>
      </div>
    );
};

export default MainTable;

