import React, { useState, useEffect } from "react";
import style from "./Food-add-form.module.scss";
import { Formik, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import InputField from "../input-field";
import { MdUploadFile , MdClose} from "react-icons/md";
import "../../styles/global.css";
import { http } from "../../utilits/http";
import Loading from "../loading";
import {toast} from 'react-toastify';

const FoodAddForm = ({
  initialFormValues,
  addFoodItem,
  isEdit,
  closeModal,
  onUpdate,
  isHandleAdd, 
  showImageField,
  onAcceptRequest,
  isAcceptForm
}) => {
  const formModeClass = isEdit ? "edit-mode" : "add-mode";
  const formModeHeading = isEdit ? "Edit food" : isHandleAdd ? "Request food" : "Add food";
  const [imageError, setImageError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showError , setShowError] = useState("");
  const [unitList, setUnitList] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState("");
  const [imageName, setImageName] = useState(initialFormValues ? initialFormValues.image_name : "");
  const [unitCount, setUnitCount] = useState(0); 
  const [customErr , setCustomErr]= useState("");

  useEffect(() => {
    const fetchUnitData = async () => {
      try {
        const response = await http.get("diet/unit-list/");
        setUnitList(response.data.results);
      } catch (error) {
        console.error("Error fetching unit data:", error);
      }
    };
    fetchUnitData();
  }, []);
  
  const resetImageInput = () => {
      setImageFile(null);
  };
 
  const validateValueField = (values) => {
    if (values.units_applicable.length > 5) {
      setCustomErr("You can only enter values for up to 5 units.");
      return false;
    }
  
    const isEmpty = values.units_applicable.some((unit) => unit.value === "");
    const hasZeroValue = values.units_applicable.some((unit) => parseFloat(unit.value) === 0);
    const hasNonNumericValue = values.units_applicable.some((unit) => isNaN(parseFloat(unit.value)));
  
    if (isEmpty) {
      setCustomErr("Please enter the value for all units.");
      return false;
    } else if (hasZeroValue) {
      setCustomErr("Please enter a valid number ");
      return false;
    } else if (hasNonNumericValue) {
      setCustomErr("Please enter numeric values for all units.");
      return false;
    } else {
      setCustomErr("");
      return true;
    }
  };
  
  const onSubmitHandler = async (values, { resetForm }) => {
    setCustomErr("")
      if (showImageField && (!isEdit || (isEdit && imageFile))) {
        if (!imageFile) {
          setImageError("please select an image");
          return;
        } else if (imageError) {
          return;
        }
      }
      const isValueFieldValid = validateValueField(values);
      if (!isValueFieldValid) {
        return;
      }

      setImageError("")

      setShowError("");
      setLoading(true);
      let formData = new FormData();
      formData.append(
        "name",
        values.name.toLowerCase()
      );
      
      formData.append("carbohydrates", values.carbohydrates);
      formData.append("fat", values.fat);
      formData.append("proteins", values.proteins);
      formData.append("calories", values.calories);
      {imageFile && formData.append("image", imageFile);}
      formData.append(
        "units_applicable",
        JSON.stringify(values.units_applicable)
      );

      if(isHandleAdd){
        try{
          addFoodItem(formData);
          console.log(formData , 'formdata')
          resetForm(formData);
          setLoading(false);
          setImageFile('');
          setSelectedUnit('');
        }
        catch(error){
          console.log('error fetching data' , error)
          setLoading(true);
      }
      }else{
          if(isEdit) {
              http
                .patch(`diet/food-item-create/${initialFormValues.id}/`, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
              })
                .then((res) => {
                  onUpdate();
                  setLoading(false);
                  resetForm('data:',formData);
                  closeModal()
                  toast.success("Food item updated successfully", {
                    onClose: () => {
                      setTimeout(() => {
                        window.location.reload(); 
                      }, 5000);
                    },
                  });
                })
                .catch((err) => {
                  console.log(err);
                  setLoading(false);
                  if (err.response) {
                    toast.error(err.response.data.message || 'Failed to add food item ');
                    setShowError(err.response.data.message);
                  } else {
                      toast.error('Failed to add food item');
                      setShowError("An error occurred while sending the request");
                  }     
                });
          } else {
            formData.append("image", imageFile);
            console.log('values',imageFile)
              http
                .post("diet/food-item-create/", formData, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                })
                .then((res) => {
                  onUpdate();
                  setLoading(false);
                  resetForm(formData);
                  resetImageInput();
                  setImageName(values.image_name);
                  if (isAcceptForm) {
                    onAcceptRequest(values.name);
                  }
                  toast.success("Food item created successfully", {
                    onClose: () => {
                      setTimeout(() => {
                        window.location.reload(); 
                      }, 6000);
                    },
                  });
                  setImageFile("")
                  setSelectedUnit("")
                })
                .catch((err) => {
                  console.log(err);
                  setLoading(false);
                  if (err.response) {
                    toast.error(err.response.data.message || 'Failed to add food item ');
                    setShowError(err.response.data.message);
                  } else {
                      toast.error('Failed to add food item');
                      setShowError("An error occurred while sending the request");
                  }                
              });
          }
        }
  };
  
  const handleClear = (resetForm) => {
    resetForm(initialFormValues);
    setImageFile("")
    setSelectedUnit("")
  };

  const VALIDATION = Yup.object().shape({
      // name: Yup.string().required("Item name is required").matches(/^[A-Za-z\s]+$/, 'Enter a valid name').max(25, 'Name must be at most 25 characters'),
      name: Yup.string()
      .required("Item name is required")
      .test('No-empty-spaces', 'Enter a valid name', (value) => {
      return value.trim() !== '';
    })
    .matches(/^[A-Za-z\s]+$/, 'Enter a valid name')
    .max(20, 'Name must be at most 20 characters'),
      calories: Yup.number().typeError("Please enter a number").required("Calorie is required").max(1000000000,"The entered value is invalid"),
      carbohydrates: Yup.number().typeError("Please enter a number").required(
        "Carbohydrates is required"
      ).max(1000000000,"The entered value is invalid"),
      fat: Yup.number().typeError('Please enter a number').required("Fat is required").max(1000000000,"The entered value is invalid"),
      proteins: Yup.number().typeError("Please enter a number").required(
        "Proteins is required"
      ).max(1000000000,"The entered value is invalid"),
      units_applicable: Yup.array().min(1, "Add atleast one unit"),
  });

  const INITIALVALUES = {
    name: "",
    calories: "",
    carbohydrates: "",
    fat: "",
    proteins: "",
    units_applicable: [],
    image: "",
    image_name: initialFormValues ? initialFormValues.image_name : "",
  };
  
  return (
    <div className={`form_container ${formModeClass}`}>
      {loading && <Loading />}
        <div className="form_head">
          {formModeHeading}
        </div>
      <hr className="seperator" />
      <Formik
        initialValues={initialFormValues ? initialFormValues : INITIALVALUES}
        validationSchema={VALIDATION}
        onSubmit={onSubmitHandler}
        enableReinitialize={true}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          resetForm,
        }) => (
          <form
            onSubmit={handleSubmit}
            className="form_wrapper"
            encType="multipart/form-data"
          >
            <div className={style.form_field}>
              <div className="row">
                <div className={`col-6 ${style.fld_input}`}>
                  <InputField
                    type="text"
                    name="name"
                    value={values.name}
                    label="Item name"
                    placeholder="Name of the food item"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    width="small"
                    isRequired={true}
                  />
                  <div className="error_msg">
                    <ErrorMessage name="name" />
                  </div>
                  <div className="error_msg">{showError && <div className="error-message">{showError.message}</div>}</div>
                </div>
                {showImageField && (
                    <div className="col-6  form_img_field">
                      <div className={style.img_label}>
                        <label htmlFor="image">Food Image <span className="image_req">*</span></label>
                      </div>
                      <div className="add_img_block">
                          <label className="label_img" htmlFor="image_label">
                              <input 
                                  type="file"
                                  name="image"
                                  id="image_label"
                                  placeholder="upload image"
                                  onBlur={handleBlur}
                                  width="large"
                                  onChange={(event) => {
                                    const file = event.target.files[0];
                                    setImageFile(file);
                                    if (!file) {
                                      setImageError("Image is required");
                                    } else {
                                      const allowedFormats = ["image/jpeg", "image/png"];
                                      if (!allowedFormats.includes(file.type)){
                                        setImageError("Invalid file format. Please upload a JPEG or PNG image")
                                      }else{
                                        setImageError("");
                                      }
                                    }
                                    handleChange(event);
                                    // console.log(imageFile)
                                  }}
                              />
                              <div className="selected_file_name">
                                {imageFile && (
                                    <div className="selected">
                                        <p>{imageFile.name}</p>
                                        <div className="file_close">
                                            <MdClose
                                                className="close_icon"
                                                onClick={() => {
                                                    setImageFile(null);
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                                {!imageFile && initialFormValues && initialFormValues.image_name && (
                                    <div className="updated">
                                        <p>{initialFormValues.image_name}</p>
                                            <MdClose
                                                className="close_icon"
                                                onClick={() => {
                                                    setImageFile(null);
                                                }}
                                            />
                                    </div>
                                )}
                                {!imageFile && (!initialFormValues || !initialFormValues.image_name) && (
                                    <div className="upload">
                                        <p>Upload image</p>
                                        <MdUploadFile />
                                    </div>
                                )}
                            </div>
                          </label>
                        <p className="image_size">Max file size is 5MB</p>
                      </div>
                      <div className="error_msg">
                          {imageError && <span>{imageError}</span>}
                          <ErrorMessage name="image" />
                      </div>
                    </div>
                )}
              </div>
            </div>
            <div className={style.inner_form}>
              <FieldArray name="units_applicable">
                {({ push, remove , form}) => (
                  <div className="row">
                    <div className={`col-6 ${style.select_wrapper}`}>
                      <div className={style.select_label}>
                        <label htmlFor="units_applicable">Units Applicable<span className="image_req">*</span></label>
                      </div>
                      <select
                        name="unit"
                        value={selectedUnit}
                        onChange={(event) => setSelectedUnit(event.target.value)}
                        onBlur={handleBlur}
                      >
                        <option value="">Select</option>
                        {unitList.map((unit) => (
                          <option key={unit.unit_id} value={unit.id}>
                            {unit.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className={`col-6 ${style.btn_wrapper}`}>
                    <button
                      type="button"
                      onClick={() => {
                         if (selectedUnit && unitCount < 3) {
                          push({ unit_id: selectedUnit, value: "" });
                          setFieldValue("unit", "");
                          setSelectedUnits([...selectedUnits, selectedUnit]);
                          setUnitCount(unitCount + 1); 
                        }
                      }}
                      disabled={!selectedUnit || unitCount >= 3}>
                      Add Unit
                    </button>
                    </div>
                    <p className={style.warning}>
                      The measurements for all items are standardized at 100
                      grams
                    </p>
                    {selectedUnits.length >= 3 && (
                      <div className="error_msg">
                        You have reached the maximum limit of 3 units.
                      </div>
                    )}
                    <div className={`col-12 ${style.unit_add}`}>
                      {values?.units_applicable?.map((unit, index) => (
                        <div className={style.unit_box} key={unit.unit_id}>
                          <div className={style.unit_labels}>
                            <div className={style.label}>
                              {unitList?.find((u) => u.id == unit.unit_id)?.name}
                            </div>
                            <button
                              onClick={() => {
                                remove(index);
                                setUnitCount(unitCount - 1); 
                              }}
                              disabled={!selectedUnits}
                            >x</button>
                          </div>
                          {!selectedUnits && (
                            <div className="error_msg">
                              Please select at least one unit.
                            </div>
                          )}
                          <input
                            type="text"
                            name={`units_applicable[${index}].value`}
                            placeholder="Enter value"
                            value={unit.value}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {/* <div className="error_msg">
                            <ErrorMessage
                              name={`units_applicable[${index}].value`}
                            />
                          </div> */}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </FieldArray>
            </div>
            <div className="error_msg">
                {customErr && (
                <span className="error-message">{customErr}</span>
              )}
            </div>
            <div className="error_msg">
              <ErrorMessage name="units_applicable" />
            </div>
            <div className={style.form_field}>
              <div className="row">
                <div className={`col-6 ${style.fld_input}`}>
                  <InputField
                    type="text"
                    name="calories"
                    value={values.calories}
                    label="Calories (per 100 gm)"
                    placeholder="Enter the value"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    width="small"
                    isRequired={true}
                  />
                  <div className="error_msg">
                    <ErrorMessage name="calories" />
                  </div>
                </div>
                <div className={`col-6 ${style.fld_input}`}>
                  <InputField
                    type="text"
                    name="carbohydrates"
                    value={values.carbohydrates}
                    label="carbohydrates (gm)"
                    placeholder="Enter the value"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    width="small"
                    isRequired={true}
                  />
                  <div className="error_msg">
                    <ErrorMessage name="carbohydrates" />
                  </div>
                </div>
              </div>
            </div>
            <div className={style.form_field}>
              <div className="row">
                <div className={`col-6 ${style.fld_input}`}>
                  <InputField
                    type="text"
                    name="fat"
                    value={values.fat}
                    label="fats (gm)"
                    placeholder="Enter the value"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    width="small"
                    isRequired={true}
                  />
                  <div className="error_msg">
                    <ErrorMessage name="fat" />
                  </div>
                </div>
                <div className={`col-6 ${style.fld_input}`}>
                  <InputField
                    type="text"
                    name="proteins"
                    value={values.proteins}
                    label="Proteins (gm)"
                    placeholder="Enter the value"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    width="small"
                    isRequired={true}
                  />
                  <div className="error_msg">
                    <ErrorMessage name="proteins" />
                  </div>
                </div>
              </div>
            </div>
            <div className="button_wrapper">
              <button
                  className="input_cancel_btn"
                  type="button"
                  onClick={() =>
                    initialFormValues ? closeModal() : handleClear(resetForm)
                  }
                >
                  {initialFormValues ? "Cancel" : "Clear"}
                </button>

              <button className="input_add_btn" type="submit">
                {initialFormValues ? "Update" : "Add"}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
export default FoodAddForm;
