import React from "react";
import style from "./NavItem.module.scss";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { BsPieChartFill } from 'react-icons/bs';
import { IoMdShareAlt } from 'react-icons/io';
import { FaUsers } from 'react-icons/fa';
import { BiSolidUser } from 'react-icons/bi';
import { RiFileCopy2Fill } from 'react-icons/ri';
import { ImSpoonKnife } from 'react-icons/im';
import { HiMail , HiSpeakerphone } from 'react-icons/hi';


const NavItem = ({ data, info }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const icons = {
      BsPieChartFill:<BsPieChartFill /> ,
      IoMdShareAlt  : <IoMdShareAlt  />,
      FaUsers : <FaUsers />,
      BiSolidUser : <BiSolidUser />,
      RiFileCopy2Fill : <RiFileCopy2Fill />,
      ImSpoonKnife : <ImSpoonKnife />,
      HiMail : <HiMail  />,
      HiSpeakerphone : <HiSpeakerphone  />
  }

  return (
    <div className={style.nav_item_wrapper}>
      <div
        className={`${style.nav_item} `}
        onClick={() => {
          data?.submenu ? setIsOpen(!isOpen) : window.location.href=`${info.backend_url+data.url}`;
          // data?.submenu ? setIsOpen(!isOpen) : navigate(`${info.backend_url + data.url}`);
        }}
      >
        <span className={style.title_wrapper}>
          <span className={style.icon}>
            {icons[data.react_icon] || null}
          </span>
          <span className={style.title}>{data.name}</span>
        </span>
        {data?.submenu && (
          <span className={`${style.arrow} ${isOpen && style.open}`}>
            <MdKeyboardArrowRight />
          </span>
        )}
      </div>
      {data.submenu && (
        <ul className={`${style.sub_list_items} ${isOpen && style.open}`}>
          {data?.submenu?.map((item, index) => {
            return (
              <li key={index}>
                <Link
                  to={
                    item?.react_routed ? item.url : info.backend_url + item?.url
                  }
                >
                  {item.name}
                </Link>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default NavItem;
