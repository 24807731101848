import React, { useState } from "react";
import style from './Unit-table.module.scss';
import MainTable from "../table";
import { BiDotsVerticalRounded } from 'react-icons/bi';
import Modal from "../modal";
import ClickAwayListener from "react-click-away-listener";

const UnitTable = ({ unitData, onDelete }) => {
    const [showMenu, setShowMenu] = useState(-1);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState('');

    const UnitFields = [
        'sl no',
        'unit name',
        'actions'
    ];

    const toggleMenu = (index) => {
        setShowMenu(showMenu === index ? -1 : index);
    };

    const handleDeleteClick = (id) => {
        setItemToDelete(id);
        setDeleteModalOpen(true);
    };

    const confirmDelete = () => {
        onDelete(itemToDelete);
        setDeleteModalOpen(false);
        setItemToDelete(null);
        setShowMenu(-1);
    };

    return (
        <div className={style.unit_table_container}>
            <MainTable headers={UnitFields}>
                {unitData?.map((data, index) => (
                    <tr className="table_body" key={index}>
                        <td className="table_body_data">{index + 1}</td>
                        <td className="table_body_data"><p>{data.name}</p></td>
                        <td className="table_body_action">
                            <div className="menu_dropdown">
                                <BiDotsVerticalRounded onClick={() => toggleMenu(index)} />
                                {showMenu === index && (
                                    <ClickAwayListener onClickAway={() => setShowMenu(false)}>
                                        <div className="dropdownContent">
                                            <div>
                                                <button className="action_button" onClick={() => handleDeleteClick(data.id)}>delete</button>
                                            </div>
                                        </div>
                                    </ClickAwayListener>
                                )}
                            </div>
                        </td>
                    </tr>
                ))}
            </MainTable>
            {deleteModalOpen && (
                <Modal showClose={false} onClose={() => setDeleteModalOpen(false)}>
                    <div>
                        <div className="modal_head">Remove unit</div>
                        <p className="modal_msg">Are you sure you want to remove the unit?</p>
                        <button className="modal_yes" onClick={confirmDelete}>Yes</button>
                        <button className="modal_no" onClick={() => setDeleteModalOpen(false)}>No</button>
                    </div>
                </Modal>
            )}
        </div>
    );
}

export default UnitTable;
