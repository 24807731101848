import React from "react";
import style from './View-enquiry.module.scss';
import DietPlan from "../plan-detail";
import Container from "../container";

const EnquiryView = () => {
    return(
        <Container>
            <div>
                <DietPlan showButton={true} />
            </div>
        </Container>
    )
} 
export default EnquiryView;