import React , { useState , useEffect } from "react";
import style from './Allergies-page.module.scss';
import Container from "../container";
import AllergyForm from "../allergy-form";
import AllergyTable from "../allergies-table";
import Heading from "../heading";
import { http } from "../../utilits/http";
import Loading from "../loading";
import {toast} from 'react-toastify';
import { useNavigate , useLocation } from "react-router-dom";
import Pagination from "../pagination";
import Search from "../search";

const AllergyPage = () => {
    const [allergyData, setallergyData] = useState({ results: [], count: 0 });
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 15;
    const navigate = useNavigate();
    const location = useLocation();

    const fetchData = async () => {
        try {
            const response = await http.get(`diet/allergies/?search=${searchTerm}&page=${currentPage + 1}`);
            setallergyData(response.data); 
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    

    useEffect(() => {
        fetchData()
    }, [searchTerm , currentPage]);

    const pageCount = Math.ceil(allergyData.count / itemsPerPage);

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
        navigate(`${location.pathname}?page=${selectedPage.selected + 1}`);
    };
    
    const filteredData = allergyData?.results?.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
   
    const handleDelete = async (id) => {
        setLoading(true)
        try {
            await http.delete(`diet/allergy-admin/${id}/`);
            // const updatedAllergyData = allergyData.filter(allergy => allergy.id !== id);
            // setallergyData(updatedAllergyData);
            fetchData();
            setLoading(false);
            toast.success("Allergy deleted successfully");
        } catch (error) {
            console.error("Error deleting allergy:", error);
            setLoading(false);
            toast.error("Failed to delete allergy");
        }
    };

    return(
        <Container>
            <div className={style.allergy_container}>
                {loading && <Loading />}
                <div className={style.allergy_page_head}>
                    <Heading name="Allergies"/>
                </div>
                <div className="row">
                    <div className="col-5">
                        <AllergyForm onUpdate={fetchData}/>
                    </div>
                    <div className="col-7">
                        <AllergyTable allergyData={filteredData} onDelete={handleDelete} onUpdate={fetchData}/>
                    </div>
                </div>
                {pageCount > 1 && (
                    <div>
                        <Pagination pageCount={pageCount} onPageChange={handlePageChange} />
                    </div>
                )}
            </div>
        </Container>
    )
}
export default AllergyPage;