import React , {useEffect, useState} from "react";
import style from './Request.module.scss';
import Container from "../container";
import Heading from "../heading";
import FoodTable from "../food-listing-table";
import FoodAddForm from "../food-add-form";
import FoodTableFc from "../request-table-fc";
import Search from "../search";
import { http } from "../../utilits/http";
import { useNavigate , useLocation } from "react-router-dom";
import Pagination from "../pagination";
import {toast} from 'react-toastify';

const RequestPage = () => {
    const [addFoodItem, setAddFoodItem] = useState({ results: [], count: 0 });
    const [initialFormValues, setInitialFormValues] = useState({ name: "" , calories:"" , carbs:"" , fat:"" , protein:"", units:[] , unit:"" , image:"" });
    const [showError , setShowError] = useState("");
    const isHandleAdd = true;
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 15;
    const navigate = useNavigate();
    const location = useLocation();
  
    const fetchData = async () => {
        try {
            const response = await http.get(`diet/fitness-food-request/?search=${searchTerm}&page=${currentPage + 1}`)
            setAddFoodItem(response.data);
        } catch (error) {
            console.log('Error fetching data', error);
        }
    }

    useEffect(()=> {
        fetchData();
    },[searchTerm,currentPage]);

    const pageCount = Math.ceil(addFoodItem.count / itemsPerPage);

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
        navigate(`${location.pathname}?page=${selectedPage.selected + 1}`);
    };
    
    const filteredData = addFoodItem.results.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSearch = (searchQuery) => {
        setSearchTerm(searchQuery);
        setCurrentPage(0);
    };

    const handleAdd = async (newFoodReq) => {
        try {
            const response = await http.post("diet/fitness-food-request-create/", newFoodReq, {
                headers: {
                "Content-Type": "multipart/form-data",
                },
            });
            fetchData();
            toast.success("Food request created successfully", {
                onClose: () => {
                  setTimeout(() => {
                    window.location.reload(); 
                  }, 6000);
                },
              });
        } catch (error) {
            console.error("Error adding diet food request:", error);
            if (error.response) {
                toast.error(error.response.data.message || 'Failed to add food item');
                setShowError(error.response.data.message);
            } else {
                toast.error('Failed to add food item');
                setShowError("An error occurred while sending the request");
            }
        }
    };

    const handleDelete = async (id) => {
        try {
            await http.delete(`diet/fitness-food-request-create/${id.id}/`);
            fetchData();
            toast.success("Food item deleted successfully")
        } catch (error) {
            console.error("Error deleting food request:", error);
            toast.error("Failed to update food item")
        }
    };
    
    return(
        <div className={style.request_page_container}>
            <div>
                <div className={style.search_section}>
                    <Search onSearch={handleSearch} />
                </div>
                <div className={style.page_heading}>
                    <Heading name="food" />
                </div>
                <div className="row">
                    <div className="col-5">
                        <FoodAddForm addFoodItem={handleAdd} isHandleAdd={isHandleAdd} showImageField={false}/>
                    </div>
                    <div className={`col-7 ${style.request_table_fc}`}>
                        <FoodTableFc addFoodItem={filteredData} onDelete={handleDelete} onUpdate={fetchData}/>
                    </div>
                </div>
                {pageCount > 1 && (
                    <div>
                        <Pagination pageCount={pageCount} onPageChange={handlePageChange} />
                    </div>
                )}
            </div>
        </div>
    )
}
export default RequestPage;