import React from 'react';
import style from './Modal.module.scss';
import {GrClose} from 'react-icons/gr'

const Modal = ({children , onClose , showClose , isLarge }) => {
    return(
        <div className={style.modal}>
            <div className={`${style.modal_content} ${isLarge ? style.large_modal : style.small_modal}`}>
                {showClose && (
                    <button className={style.close_button} onClick={onClose}>
                        <GrClose />
                    </button>
                )}
                {children}
            </div>
        </div>
    )
}
export default Modal;