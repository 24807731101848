import React, { useState } from 'react';
import { Table } from "react-bootstrap";
import style from './payment-request-tabble.module.scss';
import PaymentModal from "../paynow-modal";

const PaymentRequestTable = ( {data ,  setSelectedRows , selectedRows } ) => {
    const [selectAll, setSelectAll] = useState(false);

    const handleSelectAll = (e) => {
       const isChecked = e.target.checked;
       setSelectAll(isChecked);
        if (isChecked) {
            setSelectedRows(data?.map(item => item.id));
        } else {
            setSelectedRows([]);
        }
    };
   
    const handleSelectRow = (id) => {
        setSelectedRows(prev => {
            if (prev.includes(id)) {
                return prev.filter(item => item !== id);
            } else {
                return [...prev, id];
            }
        });
    };

    return (
        <Table className={style.table_wrapper}>
            <thead>
                <tr className={style.table_head}>
                    <th className={style.table_title}>
                        <input
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                        />
                    </th>
                    <th className={style.table_title}>Sl No</th>
                    <th className={style.table_title}>USER NAME</th>
                    <th className={style.table_title}>REQUESTED AMOUNT</th>
                    <th className={style.table_title}>REQUEST STATUS</th>
                </tr>
            </thead>
            <tbody>
                {data?.map((item , index ) => (
                    <tr key={item.id} className="table_body">
                        <td className="table_body_data">
                            <input
                                type="checkbox"
                                checked={selectedRows.includes(item.id)}
                                onChange={() => handleSelectRow(item.id)}
                            />
                        </td>
                        <td className="table_body_data">{index+1}</td>
                        <td className="table_body_data">{item.name}</td>
                        <td className="table_body_amount">₹{item?.amount}</td>
                        <td className="table_body_data">{item?.status}</td>
                    </tr>
                ))}
            </tbody>
       </Table>
    )
}
   
export default PaymentRequestTable;
   