import React, { useState } from "react";
import { useSearchParams } from "react-router-dom"; // Import useSearchParams from react-router-dom
import style from './payment-filter.module.scss';
import { GoTriangleDown , GoTriangleUp } from "react-icons/go";
import { IoMdCheckmark } from "react-icons/io";
import ClickAwayListener from "react-click-away-listener";

const PaymentRequestFilter = ({filterOptions , setFilterOptions , checked , setChecked , handleOptionSelect }) => {

    const OPTIONS = [
        { name: "All", value: "all" },
        { name: "Pending", value: "pending" },
        { name: "Paid", value: "paid" }
    ];

    const getOptionName = () => {
        const selectedOption = OPTIONS?.find(option => option.value === checked)
        return selectedOption ? selectedOption?.name : 'All'
    }

    return (
        <div className={style.filter_wrapper} onClick={() => {setFilterOptions(!filterOptions)}}>
            <div>
                <div className={style.heading}>Request Status</div>
                <button type="button">{getOptionName()}
                    <div className={style.arrow}>
                        {filterOptions ? <GoTriangleUp /> : <GoTriangleDown /> }
                    </div>
                </button>
            </div>
            {filterOptions && (
                <ClickAwayListener onClickAway={() => {setFilterOptions(false)}}>
                    <div className={style.filter_option_wrapper}>
                        {OPTIONS.map((item, index) => (
                            <div className={style.filter_options} key={index} onClick={() => handleOptionSelect(item.value)}>
                                <div>
                                    {item.name}
                                </div>
                                <div className={style.round}>
                                    {checked === item.value && (
                                        <div className={style.check}>
                                            <span className={style.tik}><IoMdCheckmark /></span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </ClickAwayListener>
            )}
        </div>
    );
}

export default PaymentRequestFilter;
