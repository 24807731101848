import React, { useEffect, useState } from "react";
import style from "./MealsPage.module.scss";
import DietPlan from "../plan-detail";
// import { Container } from "react-bootstrap";
import Heading from "../heading";
import MealForm from "../meal-add-form";
import MealTable from "../meal-table";
import { http } from "../../utilits/http";
import PlanDetails from "../plan-details";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Modal from "../modal";
import sucsess from "../../assets/images/check (2) 1.png";

const MealsPage = () => {
  const [addMeal, setAddMeal] = useState([]);

  const [data, setData] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const params = useParams();

  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate()

  const getData = () => {
    setLoading(true);
    http
      .get(`/diet/diet-plan-detail/${params?.id}/`)
      .then((res) => {
        setLoading(false);
        setData(res?.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleOKButtonClick = () => {
    http
      .patch(`diet/is_shown/${params?.planId}/`)
      .then((response) => {
        setIsModalOpen(false);
      })
      .catch((error) => {
        setIsModalOpen(false);
      });
  };
  useEffect(() => {
    //getting diet paln details
    getData();
  }, []);

  return (
    <div className={style.meal_page_container}>
      <div className={style.meal_add_section}>
        <div className="row">
          <div className={style.heading}>
            <Heading name="meal" />
            <button className="input_cancel_btn" onClick={()=>{
              navigate(-1)
            }}>Back</button>
          </div>
          <div className={`col-6 ${style.meal_form_col}`}>
            <MealForm
              onUpdate={getData}
              dietPlanId={params?.id}
              userDietPlanId={params?.id}
              isBaseMeal={true}
            />
          </div>
          <div className={`col-6 ${style.meal_table}`}>
            <MealTable
              meals={data?.meals}
              addMeal={addMeal}
              onUpdate={getData}
              userDietPlanId={params?.id}
              dietPlanId={data?.diet_plan?.id}
              isBaseMeal={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default MealsPage;
