import React , {useState} from "react";
import style from './Allergies-table.module.scss'
import MainTable from "../table";
import '../../styles/global.css';
import AllergyForm from '../allergy-form';
import Modal from "../modal";
import { BiDotsVerticalRounded } from 'react-icons/bi';
import ClickAwayListener from "react-click-away-listener";

const AllergyTable = ({allergyData , onDelete , onUpdate}) => {
    const AllergyFields = [
        'sl no' ,
        'allergies name',
        'actions'
    ];

    const [showMenu, setShowMenu] = useState(-1);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null); 
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [itemToUpdate, setItemToUpdate] = useState(null); 

    const toggleMenu = (index) => {
        setShowMenu(showMenu === index ? -1 : index);
    };

    const handleDeleteClick = (id) => {
        setItemToDelete(id); 
        setDeleteModalOpen(true); 
    };

    const confirmDelete = () => {
        onDelete(itemToDelete); 
        setDeleteModalOpen(false); 
        setItemToDelete(null);
        setShowMenu(-1); 

    };

    const handleUpdateClick = (data) => {
        setItemToUpdate(data); 
        setUpdateModalOpen(true); 
    };
    
    const confirmUpdate = (updatedData) => {
        onUpdate(updatedData); 
        setUpdateModalOpen(false); 
        setItemToUpdate(null); 
        setShowMenu(-1); 

    };

    return(
        <div className={style.allergies_table_container}>
            <MainTable headers={AllergyFields} >
                {allergyData?.map((data, index) => (
                    <tr className="table_body" key={index}>
                        <td className="table_body_data">{index+1}</td>
                        <td className="table_body_data"><p>{data.name}</p></td>
                        <td className="table_body_action">
                            <div className="menu_dropdown">
                                <BiDotsVerticalRounded onClick={() => toggleMenu(index)} />
                                {showMenu === index && (
                                    <ClickAwayListener onClickAway={()=>setShowMenu(false)}>
                                        <div className="dropdownContent">
                                            <div>
                                                <button className="action_button" onClick={() => handleUpdateClick(data)}>Edit</button>
                                            </div>
                                            <div>
                                                <button className="action_button" onClick={() => handleDeleteClick(data.id)}>Delete</button>
                                            </div>
                                        </div>
                                    </ClickAwayListener>
                                )}
                            </div>
                        </td>
                    </tr>
                ))}
            </MainTable>
            {deleteModalOpen && (
                <Modal closeModal={() => setDeleteModalOpen(false)}>
                    <div>
                        <div className="modal_head">Remove allergy</div>
                        <p className="modal_msg">Are you sure do you want to remove the allergy?</p>
                        <button className="modal_yes" onClick={confirmDelete}>Yes</button>
                        <button className="modal_no" onClick={() => setDeleteModalOpen(false)}>Cancel</button>
                    </div>
                </Modal>
            )}
            {updateModalOpen && (
                <Modal onClose={() => setUpdateModalOpen(false)} showClose>
                    <AllergyForm
                        initialFormValues={itemToUpdate}
                        onUpdate={confirmUpdate}
                        closeModal={() => setUpdateModalOpen(false)}     
                        isEdit={!!itemToUpdate} 
                    />
                </Modal>
            )}
        </div>
    )
}
export default AllergyTable;