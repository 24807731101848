import React, { useState } from "react";
import style from "./Meal-table.module.scss";
import MainTable from "../table";
import { BiDotsVerticalRounded } from "react-icons/bi";
import Modal from "../modal";
import "../../styles/global.css";
import MealForm from "../meal-add-form";
import { http } from "../../utilits/http";
import Loading from "../loading";

const MealTable = ({ meals, addMeal, onDelete, onUpdate, dietPlanId ,userDietPlanId,isBaseMeal}) => {
  const [showMenu, setShowMenu] = useState(-1);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [itemToDelete, setItemToDelete] = useState("");
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [itemToUpdate, setItemToUpdate] = useState(null);

  const MealFields = ["sl.no", "meal", "time", "calories", "actions"];

  const toggleMenu = (index) => {
    setShowMenu(showMenu === index ? -1 : index);
  };

  const handleDeleteClick = (data) => {
    setItemToDelete(data.id);
    setDeleteModalOpen(true);
  };

  const confirmDelete = () => {
    setLoading(true);
    http
      .delete(`diet/base-meal/${itemToDelete}`)
      .then((res) => {
        setLoading(false);
        onUpdate();
        setDeleteModalOpen(false);
        setShowMenu(false);
      })
      .catch((err) => console.log(err), setLoading(false));
  };

  const handleUpdateClick = (data) => {
    setItemToUpdate(data);
    console.log(data);
    setUpdateModalOpen(true);
  };

  const confirmUpdate = (updatedData) => {
    onUpdate(updatedData);
    setUpdateModalOpen(false);
    setItemToUpdate(null);
  };

  return (
    <div className={style.meal_table_container}>
      {loading && <Loading />}
      <MainTable headers={MealFields}>
        {meals?.map((data, index) => {
          return (
            <tr className="table_body" key={index}>
              <td className="table_body_data">{index + 1}</td>
              <td className="table_body_data"><p>{data.name}</p></td>
              <td className="table_body_data">{data.time}</td>
              <td className="table_body_data">{data.total_calories}</td>
              <td>
                <div className="menu_dropdown">
                  <BiDotsVerticalRounded onClick={() => toggleMenu(index)} />
                  {showMenu === index && (
                    <div className="dropdownContent">
                      <div>
                        <button
                          className="action_button"
                          onClick={() => handleUpdateClick(data)}
                        >
                          Edit
                        </button>
                      </div>
                      <div>
                        <button
                          className="action_button"
                          onClick={() => handleDeleteClick(data)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </td>
            </tr>
          );
        })}
      </MainTable>
      {deleteModalOpen && (
        <Modal closeModal={() => setDeleteModalOpen(false)}>
          <div>
            <div className="modal_head">Remove diet</div>
            <p className="modal_msg">
              Are you sure do you want to remove the diet?
            </p>
            <button className="modal_yes" onClick={confirmDelete}>
              Yes
            </button>
            <button
              className="modal_no"
              onClick={() => setDeleteModalOpen(false)}
            >
              Cancel
            </button>
          </div>
        </Modal>
      )}
      {updateModalOpen && (
        <Modal
          onClose={() => {
            setShowMenu(false);
            setUpdateModalOpen(false);
          }}
          showClose
        >
          <MealForm
            initialFormValues={itemToUpdate || {}}
            onUpdate={onUpdate}
            onClose={() => {
              setShowMenu(false);
              setUpdateModalOpen(false);
            }}
            dietPlanId={dietPlanId}
            userDietPlanId={userDietPlanId}
            onSubmit={(updatedData) => {
              confirmUpdate(updatedData);
              setUpdateModalOpen(false);
            }}
            isBaseMeal={isBaseMeal}
          />
        </Modal>
      )}
    </div>
  );
};
export default MealTable;
