import React , {useState} from "react";
import style from './Unit-form.module.scss';
import { Formik , ErrorMessage } from "formik";
import * as Yup from "yup";
import InputField from "../input-field";
import '../../styles/global.css';
import { http } from "../../utilits/http";
import {toast} from 'react-toastify';

const Unitform = ({ initialFormValues, onUpdate, isEdit }) => {  
    const formModeClass = isEdit ? 'edit-mode' : 'add-mode';  
    const [loading , setLoading] = useState("");
    const [showError , setShowError] = useState("");

    const onSubmitHandler = async (values, { resetForm }) => {
        setShowError("");
        setLoading(true);
        values.name = values.name.toLowerCase();
        // console.log(values.name);
        http.post('diet/unit/', values)
        .then((res) =>{
            resetForm('data:', values);
            onUpdate();
            toast.success("Unit created successfully")
            setLoading(false)
            console.log(values);
        })
        .catch((err) => {
            console.log(err);
            setLoading(false);
            if (err.response) {
                toast.error(err.response.data.message || 'Failed to add unit');
                setShowError(err.response.data.message);
            } else {
                toast.error('Failed to add unit');
                setShowError("An error occurred while sending the request");
            }
        });
    };

    const  handleClear = (resetForm) => {   
        resetForm(initialFormValues);
    }
    const INITIALVALUES = {
        name: initialFormValues ? initialFormValues.name : "",
    };

    return(
        <div className={`form_container ${formModeClass}`}>
            <div className="form_head">
                Add unit
            </div>
            <hr className="seperator"/>
            <Formik 
                initialValues={INITIALVALUES}
                validationSchema={Yup.object().shape({
                    name: Yup.string()
                        .required("Unit name is required")
                        .test('No-empty-spaces', 'Enter a valid unit name', (value) => {
                        return value.trim() !== '';
                    })
                })}
                onSubmit={onSubmitHandler}  
            >
            {({ values , handleSubmit , handleChange , handleBlur , resetForm }) => (
               <form
                    onSubmit={handleSubmit}
                    className="form_wrapper"
                >  
                    <div className={style.form_field}>
                        <InputField 
                            type="text"
                            name="name"
                            value={values.name}
                            label="Name"
                            placeholder="Enter the unit"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            width="small"
                            isRequired={true}
                        />
                    </div>
                    <div className="error_msg">
                        <ErrorMessage name="name" />
                    </div>
                    {/* <div className="error_msg">{showError && <div className="error-message">{showError.message}</div>}</div> */}
                    <div className="button_wrapper">
                        <button className="input_cancel_btn" type="reset" onClick={() => handleClear(resetForm)}>
                            Clear
                        </button>
                        <button className="input_add_btn" type="submit">
                            Add
                        </button>
                    </div>
                </form>
            )}
            </Formik>
        </div>
    )
}

export default Unitform;

