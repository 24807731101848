import React, { useState , useEffect } from "react";
import style from './Meal-listing.module.scss';
import MainTable from "../table";
import Heading from "../heading";
import { useParams } from "react-router-dom";
import { http } from "../../utilits/http";
import DietPlan from "../plan-detail";
import PlanDetails from "../plan-details";

const MealList = () => {
    const params = useParams();
    const [data , setData] = useState("")
    const MealFields = ["sl.no", "meal", "time", "calories"];

    const fetchData = async () => {
        try {
            const response = await http.get(`diet/user-diet-plan/${params?.planId}/`)
            setData(response.data);
            console.log(response.data, 'res')
        } catch (error) {
            console.log('Error fetching data', error);
        }
    }
    useEffect(() => {
        fetchData();
      }, []);
    return(
        <div className={style.meal_table_container}>
            <div className={style.diet_plan_section}>
                <DietPlan data={data} onUpdate={fetchData} showedit={false}/>
            </div>
            <div className={style.plan_section}>
                <PlanDetails data={data} />
            </div>
            <div className={style.meal_table}>
                <div className={style.diet_head}>
                    <Heading name="Meals" />
                </div>
                <MainTable headers={MealFields}>
                    {data?.meals?.map((data, index) => {
                    return (
                        <tr className="table_body" key={index}>
                            <td className="table_body_data">{index + 1}</td>
                            <td className="table_body_data"><p>{data.name}</p></td>
                            <td className="table_body_data">{data.time}</td>
                            <td className="table_body_data">{data.total_calories}</td>
                            {/* <td className="table_body_data">{data.total_fat}</td>
                            <td className="table_body_data">{data.total_proteins}</td>
                            <td className="table_body_data">{data.total_carbohydrates}</td> */}
                        </tr>
                    );
                    })}
                </MainTable>
            </div>
        </div>
    )
}
export default MealList;