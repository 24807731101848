import React , {useState,useEffect} from "react";
import style from './Request-listing.module.scss';
import Container from "../container";
import RequestList from "../request-list-table";

const RequestListAdmin = () => {
    
    return(
        <Container>
            <div className={style.request_page_container}>
                <div className={style.request_list_content}>
                    <RequestList />
                </div>
            </div>
        </Container>
    )
}
export default RequestListAdmin;