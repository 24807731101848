import React , {useState} from "react";
import style from './Diet-preference-table.module.scss';
import MainTable from "../table";
import DietPrefernceForm from "../diet-preference-form";
import Modal from "../modal";
import { BiDotsVerticalRounded } from 'react-icons/bi';
import ClickAwayListener from "react-click-away-listener";

const DietTable = ({ dietData , onUpdate , onDelete }) => {
    const DietFields = [
        'sl no' ,
        'preference name',
        'image',
        'actions'
    ];
    const [showMenu, setShowMenu] = useState(-1);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null); 
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [itemToUpdate, setItemToUpdate] = useState(null); 

    const toggleMenu = (index) => {
        setShowMenu(showMenu === index ? -1 : index);
    };

    const handleDeleteClick = (id) => {
        setItemToDelete(id); 
        setDeleteModalOpen(true); 
    };

    const confirmDelete = () => {
        onDelete(itemToDelete); 
        setDeleteModalOpen(false); 
        setItemToDelete(null);
        setShowMenu(-1); 
    };

    const handleUpdateClick = (data) => {
        setItemToUpdate(data); 
        setUpdateModalOpen(true); 
        setShowMenu(-1); 
    };
    
    const confirmUpdate = (updatedData) => {
        onUpdate(updatedData); 
        setUpdateModalOpen(false); 
        setItemToUpdate(null); 
    };

    return(
        <div className={style.preference_table_container}>
            <MainTable headers={DietFields}>
                {dietData?.map((data, index) => (
                    <tr className="table_body" key={index}>
                        <td className="table_body_data">{index+1}</td>
                        <td className="table_body_data"><p>{data.name}</p></td>
                        <td className="table_body_data">
                            <img src={data.image} alt={data.name} />
                        </td>
                        <td className="table_body_action">
                            <div className="menu_dropdown">
                                <BiDotsVerticalRounded onClick={() => toggleMenu(index)} />
                                {showMenu === index && (
                                    <ClickAwayListener onClickAway={()=> setShowMenu(false)}>
                                        <div className="dropdownContent">
                                            <div>
                                                <button className="action_button" onClick={() => handleUpdateClick(data)}>Edit</button>
                                            </div>
                                            <div>
                                                <button className="action_button" onClick={() => handleDeleteClick(data.id)}>Delete</button>
                                            </div>
                                        </div>
                                    </ClickAwayListener>
                                )}
                            </div>
                        </td>
                    </tr>
                ))}
            </MainTable>
            {deleteModalOpen && (
                <Modal onClose={() => setDeleteModalOpen(false)}>
                    <div>
                        <div className="modal_head">Remove diet preference</div>
                        <p className="modal_msg">Are you sure do you want to remove the diet preference?</p>
                        <button className="modal_yes" onClick={confirmDelete}>Yes</button>
                        <button className="modal_no" onClick={() => setDeleteModalOpen(false)}>Cancel</button>
                    </div>
                </Modal>
            )}
             {updateModalOpen && (
                <Modal onClose={() => setUpdateModalOpen(false)} showClose>
                    <DietPrefernceForm 
                        initialFormValues={itemToUpdate} 
                        onUpdate={confirmUpdate} 
                        closeModal={() => setUpdateModalOpen(false)}
                        isEdit={!!itemToUpdate}/>
                </Modal>
            )}
        </div>
    )
}
export default DietTable;