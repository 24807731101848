import React from 'react'
import style from "./Loading.module.scss";

const Loading = () => {
  return (
    <div className={style.loading}>
    </div>
  )
}

export default Loading
