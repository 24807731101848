import React from "react";
import style from "./MultiSelectBox.module.scss";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { useState } from "react";
import ClickAwayListener from "react-click-away-listener";
import { ErrorMessage } from "formik";

const MultiSelectBox = ({
  options,
  placeHolder,
  name,
  values,
  onChange,
  onBlur,
  setFieldValue,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);

  const selectedNames = options
  .filter(element => values.includes(element.id))
  .map(element => element.name);

  const toggleDropDown = () => {
    setShowDropDown(!showDropDown);
  };

  const handleChange = (fruitId) => {
    const updatedValue = values.includes(fruitId)
      ? values.filter((id) => id !== fruitId)
      : [...values, fruitId];

    setFieldValue(name, updatedValue);
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        setShowDropDown(false);
      }}
    >
      <div className={style.multi_select_box}>
        <div className={style.selected_items}>
          {selectedNames.map((item)=>(
            <span className={style.selected_item}>{item}</span>
          ))}
        </div>
        <div className={style.select_box} onClick={toggleDropDown}>
          <span>{placeHolder}</span>
          <span>
            <MdOutlineKeyboardArrowDown />
          </span>
        </div>
        {showDropDown && (
          <div className={`${style.drop_down}`}>
            {options.map((option) => {
              return (
                <div className={style.option}>
                  <input
                    type="checkbox"
                    id={option.id}
                    checked={values.includes(option.id)}
                    name={name}
                    onChange={() => {
                      handleChange(option.id);
                    }}
                    onBlur={onBlur}
                    value={option.id}
                  />
                  <label htmlFor={option.id}>{option.name}</label>
                </div>
              );
            })}
          </div>
        )}
        <ErrorMessage name={name} />
      </div>
    </ClickAwayListener>
  );
};

export default MultiSelectBox;
