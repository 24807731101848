import React from "react";
import style from './paynow-modal.module.scss';
import Modal from '../../components/modal';

const PaymentModal = ({setPaymentModal , handlePayment}) => {
    return(
        <Modal onClose={() => {setPaymentModal(false)}}>
            <div className={style.heading}>
                Payment
            </div>
            <div className={style.content}>
                Are you sure do you want to pay to the selected users?
            </div>
            <div className={style.modal_btns}>
                <button type="button" className={style.btn_yes} onClick={handlePayment}>Yes</button>
                <button type="button" className={style.btn_no} onClick={() => {setPaymentModal(false)}}>No</button>
            </div>
        </Modal>
    )
}
export default PaymentModal;