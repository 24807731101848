import React from "react";
import style from './Heading.module.scss';

const Heading = ({name}) => {
    return(
        <div className={style.heading}>
            {name}
        </div>
    )
}
export default Heading;