import React from 'react'
import style from "./PlanDetails.module.scss";
import Heading from '../heading';

const PlanDetails = ({data}) => {
  return (
    <div className={style.plan_details}>
       <Heading name="plan" />
          <div className={`row ${style.diet_row}`}>
            <div className='col-4'>
                <div className={style.list_item}>
                    <div className={style.item_name}>Plan Name</div>:<div className={style.item_value}>{data?.diet_plan?.name}</div>
                </div>
            </div>

            <div className='col-4'>
                <div className={style.list_item}>
                    <div className={style.item_name}>Price</div>:<div className={style.item_value}>{data?.diet_plan?.price}</div>
                </div>
            </div>

            <div className='col-4'>
                <div className={style.list_item}>
                    <div className={style.item_name}>Plan status</div>:<div className={style.item_value}>{data?.diet_plan?.is_active?"Active":"Inactive"}</div>
                </div>
            </div>

            <div className='col-4'>
                <div className={style.list_item}>
                    <div className={style.item_name}>duration</div>:<div className={style.item_value}>{data?.diet_plan?.duration}</div>
                </div>
            </div>
            {data?.payment?.map((payment, index) => (
                <div className='col-4' key={index}>
                    <div className={style.list_item}>
                    <div className={style.item_name}>Payment method</div>:<div className={style.item_value}>{payment.payment_method}</div>
                    </div>
                </div>
            ))}
          </div>
    </div>
  )
}

export default PlanDetails
