import React, { useState } from "react";
import style from './Request-table-fc.module.scss';
import MainTable from "../table";
import { BiDotsVerticalRounded } from 'react-icons/bi';
import Modal from "../modal";
import '../../styles/global.css';
import ClickAwayListener from "react-click-away-listener";

const FoodTableFc = ({ addFoodItem, onDelete , onUpdate }) => {
    const [showMenu, setShowMenu] = useState(-1);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);

    const FoodFcFields = ["sl.no", "food item", "calories (per 100 gm)", "carbs (gm)", "fat (gm)", "proteins (gm)", "status", "actions"];

    const toggleMenu = (index) => {
        setShowMenu(showMenu === index ? -1 : index);
    };

    const handleDeleteClick = (data) => {
        setItemToDelete(data);
        setDeleteModalOpen(true);
    };

    const confirmDelete = () => {
        // onDelete(itemToDelete);
        setDeleteModalOpen(false);
        setItemToDelete(null);
        onDelete(itemToDelete)
        setShowMenu(-1)
    };

    return (
        <div className={style.food_list_table}>
        <MainTable headers={FoodFcFields}>
            {addFoodItem.map((data, index) => {
            // const status = data.approved ? "active" : "pending"; 
            return (
                <tr className="table_body" key={index}>
                <td className="table_body_data">{index+1}</td>
                <td className="table_body_data"><p>{data.name}</p></td>
                <td className="table_body_data">{data.calories}</td>
                <td className="table_body_data">{data.carbohydrates}</td>
                <td className="table_body_data">{data.fat}</td>
                <td className="table_body_data">{data.proteins}</td>
                <td className={`table_body_data ${data.approved === "PENDING" ? 'pending-status' : data.approved === "ACCEPT" ? 'active-status' : 'rejected-status'}`}>
                    {data.approved === "PENDING" ? "Pending" : data.approved === "ACCEPT" ? "Accepted" : "Rejected"}
                </td>
                <td className="table_body_action">
                    <div className="menu_dropdown">
                    <BiDotsVerticalRounded onClick={() => toggleMenu(index)} />
                        {showMenu === index && (
                            <ClickAwayListener onClickAway={()=> setShowMenu(false)}>
                                <div className="dropdownContent">
                                    <div>
                                        <button className="action_button" onClick={() => handleDeleteClick(data)}>delete</button>
                                    </div>
                                </div>
                            </ClickAwayListener>
                        )}
                    </div>
                </td>
                </tr>
            );
            })}
        </MainTable>
        {deleteModalOpen && (
            <Modal closeModal={() => setDeleteModalOpen(false)}>
            <div>
                <div className="modal_head">Remove food request</div>
                <p className="modal_msg">Are you sure do you want to remove the request?</p>
                <button className="modal_yes" onClick={confirmDelete}>Yes</button>
                <button className="modal_no" onClick={() => setDeleteModalOpen(false)}>Cancel</button>
            </div>
            </Modal>
        )}
        </div>
    );
};

export default FoodTableFc;
